body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tableHeader {
  background-color: #cfdbe2;
  font-family: 'Segoe UI';
  height: 50px;
}

.table thead th {
  border: 1px solid #eee !important;
  text-align: center;
}

.pagination {
  float: right;
}